<template>
  <div class="content">
    <!--（表单）选项区域-->
    <el-form ref="form" class="bgFFF" :model="form" label-width="100px">
      <!--选项区域第一行-->
      <div class="optionAreaOne">
        <el-form-item label="退款订单号:">
          <el-input
            v-model="form.input1"
            placeholder="请输入内容"
            style="width: 180px"
            maxlength="19"
          ></el-input>
        </el-form-item>
        <el-form-item label="停车场名称:">
          <my-component ref="parkInput" @valueChange="completeValue" slaveRelations="0,1">
          </my-component>
        </el-form-item>

        <el-form-item label="车牌号:">
          <el-autocomplete
            ref="plateNumber"
            size="11"
            valueKey="plateNumber"
            class="inline-input"
            v-model="form.plateNumber"
            :fetch-suggestions="querySearchAsync"
            placeholder="车牌号"
            :trigger-on-focus="false"
            @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <!-- <el-form-item label="退款类型:">
            <el-select v-model="form.refundType" placeholder="请选择">
              <el-option
                v-for="item in refundTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item> -->
        <el-form-item :label="$t('searchModule.Payment_method')">
          <el-select v-model.trim="form.payType" filterable size="8" placeholder="全部">
            <el-option label="全部" value=""></el-option>
            <el-option
              :label="item.payTypeName"
              :value="item.payType"
              :key="item.payType"
              v-for="item in payTypeStatus"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <!--选项区域第一行-->
      <div class="optionAreaThree">
        <el-form-item label="申请时间">
          <!-- <el-date-picker v-model="date1"
                          type="datetime"
                          placeholder="选择日期"
                          @change="showLog"> </el-date-picker> 至
          <el-date-picker v-model="date2"
                          type="datetime"
                          placeholder="选择日期"
                          @change="showLog"> </el-date-picker> -->
          <timeRangePick
            @timeChange="timeChange"
            ref="timeRangePicker"
            :defalutDate="defalutDate"
          />
        </el-form-item>
        <el-button
          type="primary"
          style="height: 40px; width: 100px; margin-left: 10px"
          @click="
            pageNum = 1;
            refundRecordlist();
          "
          >{{ $t('button.search') }}</el-button
        >
      </div>
    </el-form>
    <!--列表-->
    <div class="tableWrapper paddingB20">
      <el-table  :data="tableData" style="width: 100%">
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
        <el-table-column
          align="center"
          v-if="$route.meta.authority.button.ensure || $route.meta.authority.button.detail"
          label="操作"
          width="180"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="affirmAgo(scope)"
              v-if="$route.meta.authority.button.ensure"
              >确认退款</el-button
            >
            <el-button
              type="text"
              @click="particularsAgo(scope)"
              v-if="$route.meta.authority.button.detail"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block" style="text-align: right">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!--确认退款弹窗-->
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
      <span>确认退款该订单？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirm">确 定</el-button>
      </span>
    </el-dialog>
    <!--详情弹窗-->
    <el-dialog
      title="退款确认"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
    >
      <h1>停车记录</h1>
      <el-table  :data="tableData2" style="width: 100%">
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols2"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
      </el-table>
    </el-dialog>
    <!--详情弹窗-->
    <el-dialog
      title="退款确认"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
    >
      <el-steps
        :space="200"
        :active="2"
        finish-status="success"
        style="margin-left: 24%"
        align-center
      >
        <el-step title="申请退款"></el-step>
        <el-step title="审批退款"></el-step>
        <el-step title="确认退款"></el-step>
      </el-steps>
      <h1 class="lineHeight60">停车记录</h1>
      <el-table  :data="tableData2" style="width: 100%">
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols2"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
      </el-table>

      <h1 class="lineHeight60">支付记录</h1>
      <el-table  :data="tableData3" style="width: 100%">
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols3"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
      </el-table>
      <el-form ref="form" :model="dialogForm" label-width="100px" class="recordForm">
        <el-form-item label="退款原因:">
          <span>{{ dialogForm.refundReasonName }}</span>
        </el-form-item>
        <el-form-item label="退款方式:">
          <span>原路退回</span>
        </el-form-item>
        <!--实际出场时间-->
        <el-form-item label="实际出场时间:" v-show="dialogForm.region === 0">
          <span>{{ dialogForm.exitTime }}</span>
        </el-form-item>
        <el-form-item label="退款计算:">
          <el-table :data="moneyShow" style="width: 100%">
            <el-table-column align="center" label="">
              <template slot-scope="scope">
                <p v-show="scope.$index == 0">原订单</p>
                <p v-show="scope.$index == 1">修改后</p>
                <p v-show="scope.$index == 2">退款</p>
                <!--<p>原订单</p>-->
                <!--<p>修改后</p>-->
                <!--<p>退款</p>-->
              </template>
            </el-table-column>
            <el-table-column align="center" prop="date" label="应付金额">
              <template slot-scope="scope">
                <p v-if="dialogForm.refundReason == 0 && scope.$index == 0">
                  {{ scope.row.shouldPayMoney ? (actualPayMoneyVal / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="dialogForm.refundReason == 0 && scope.$index == 1">
                  {{
                    scope.row.shouldPayMoney != undefined
                      ? (scope.row.shouldPayMoney / 100).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <!--<p v-if="dialogForm.region == 0 && scope.$index == 2">{{currentChargeVO.shouldPayMoney ? ((originalChargeVO.shouldPayMoney ? originalChargeVO.shouldPayMoney : 0) - (currentChargeVO.shouldPayMoney ? currentChargeVO.shouldPayMoney : 0)) / 100 .toFixed(2) : ''}}</p>-->
                <p v-if="dialogForm.refundReason == 1 && scope.$index == 1">0.00</p>
                <!--<p v-if="dialogForm.region == 1 && scope.$index == 2">{{scope.row.shouldPayMoney ? (scope.row.shouldPayMoney / 100).toFixed(2) : ''}}</p>-->
                <p v-if="dialogForm.refundReason == 1 && scope.$index == 0">
                  {{
                    scope.row.shouldPayMoney ? (scope.row.shouldPayMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 2 && scope.$index == 0">
                  {{
                    scope.row.shouldPayMoney ? (scope.row.shouldPayMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <!--<input style="width: 80%" type="text" v-model="scope.row.shouldPayMoney" v-if="dialogForm.region === 2 && scope.$index === 2">-->
              </template>
            </el-table-column>
            <el-table-column align="center" prop="name" label="停车卡抵扣">
              <template slot-scope="scope">
                <p v-if="dialogForm.refundReason == 0 && scope.$index == 0">
                  {{
                    scope.row.parkCardMoney ? (scope.row.parkCardMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 0 && scope.$index == 1">
                  {{
                    scope.row.parkCardMoney != undefined
                      ? (scope.row.parkCardMoney / 100).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 0 && scope.$index == 2">
                  {{
                    currentChargeVO.parkCardMoney != undefined
                      ? Number(
                          (originalChargeVO.parkCardMoney - currentChargeVO.parkCardMoney) / 100
                        ).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 1 && scope.$index == 1">0.00</p>
                <p v-if="dialogForm.refundReason == 1 && scope.$index == 2">
                  {{
                    scope.row.parkCardMoney ? (scope.row.parkCardMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 1 && scope.$index == 0">
                  {{
                    scope.row.parkCardMoney ? (scope.row.parkCardMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 2 && scope.$index == 0">
                  {{
                    scope.row.parkCardMoney ? (scope.row.parkCardMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 2 && scope.$index == 2">
                  {{ otherReason.parkCardMoney }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="address" label="优惠券金额">
              <template slot-scope="scope">
                <!--<p v-if="dialogForm.region !== 2|| scope.$index !== 2">{{ scope.row.couponMoney }}</p>-->
                <p v-if="dialogForm.refundReason == 0 && scope.$index == 0">
                  {{ scope.row.couponMoney ? (scope.row.couponMoney / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="dialogForm.refundReason == 0 && scope.$index == 1">
                  {{
                    scope.row.couponMoney != undefined
                      ? (scope.row.couponMoney / 100).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 0 && scope.$index == 2">
                  {{
                    currentChargeVO.couponMoney != undefined
                      ? Number(
                          (originalChargeVO.couponMoney - currentChargeVO.couponMoney) / 100
                        ).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 1 && scope.$index == 1">0.00</p>
                <p v-if="dialogForm.refundReason == 1 && scope.$index == 2">
                  {{ scope.row.couponMoney ? (scope.row.couponMoney / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="dialogForm.refundReason == 1 && scope.$index == 0">
                  {{ scope.row.couponMoney ? (scope.row.couponMoney / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="dialogForm.refundReason == 2 && scope.$index == 0">
                  {{ scope.row.couponMoney ? (scope.row.couponMoney / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="dialogForm.refundReason == 2 && scope.$index == 2">
                  {{ otherReason.couponMoney }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="address" label="车场折扣金额">
              <template slot-scope="scope">
                <p v-if="dialogForm.refundReason == 0 && scope.$index == 0">
                  {{
                    scope.row.discountMoney ? (scope.row.discountMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 0 && scope.$index == 1">
                  {{
                    scope.row.discountMoney != undefined
                      ? (scope.row.discountMoney / 100).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 0 && scope.$index == 2">
                  {{
                    currentChargeVO.discountMoney != undefined
                      ? Number(
                          (originalChargeVO.discountMoney - currentChargeVO.discountMoney) / 100
                        ).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 1 && scope.$index == 0">
                  {{
                    scope.row.discountMoney ? (scope.row.discountMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 1 && scope.$index == 1">0.00</p>
                <p v-if="dialogForm.refundReason == 1 && scope.$index == 2">
                  {{
                    scope.row.discountMoney ? (scope.row.discountMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 2 && scope.$index == 0">
                  {{
                    scope.row.discountMoney ? (scope.row.discountMoney / 100).toFixed(2) : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 2 && scope.$index == 2">
                  {{ otherReason.discountMoney }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="address" label="实付金额">
              <template slot-scope="scope">
                <p v-if="dialogForm.refundReason == 0 && scope.$index == 0">
                  {{ scope.row.money ? (actualPayMoneyVal / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="dialogForm.refundReason == 0 && scope.$index == 1">
                  {{ scope.row.money != undefined ? (scope.row.money / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="dialogForm.refundReason == 0 && scope.$index == 2">
                  {{
                    currentChargeVO.money != undefined
                      ? Number((actualPayMoneyVal - currentChargeVO.money) / 100).toFixed(2)
                      : "0.00"
                  }}
                </p>
                <p v-if="dialogForm.refundReason == 1 && scope.$index == 0">
                  {{ (scope.row.money / 100).toFixed(2) }}
                </p>
                <p v-if="dialogForm.refundReason == 1 && scope.$index == 1">0.00</p>
                <p v-if="dialogForm.refundReason == 1 && scope.$index == 2">
                  {{ scope.row.money ? (scope.row.money / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="dialogForm.refundReason == 2 && scope.$index == 0">
                  {{ scope.row.money ? (scope.row.money / 100).toFixed(2) : "0.00" }}
                </p>
                <p v-if="dialogForm.refundReason == 2 && scope.$index == 2">
                  {{ otherReason.money }}
                </p>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="备注:">
          <span>{{ dialogForm.remarks }}</span>
        </el-form-item>
      </el-form>
      <!--<h1 class="lineHeight60">退款记录8</h1>-->
      <!--<el-table border-->
      <!---->
      <!--:data="tableData6"-->
      <!--style="width: 100%">-->
      <!--<el-table-column v-if="item.prop != 'actualExitTime' || showParkRecord"-->
      <!--align='center'-->
      <!--:prop="item.prop"-->
      <!--:label="item.label"-->
      <!--:width="item.width"-->
      <!--v-for="item in tableCols4"-->
      <!--:key="item.prop"-->
      <!--:formatter="item.formatter"></el-table-column>-->
      <!--</el-table>-->

      <h1 class="lineHeight60">操作记录</h1>
      <el-table  :data="tableData5" style="width: 100%">
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols5"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" v-if="$route.meta.authority.button.ensure" @click="affirm"
          >确认退款</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import timeRangePick from "@/components/timePicker";
import { setIndex, dateFormat } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
// import { resolve } from 'url';
export default {
  name: "affirm",
  data() {
    return {
      dialogForm: {},
      defalutDate: [],
      total: 0,
      showParkRecord: false,
      dialogVisible: false, // todo
      centerDialogVisible: false,
      pageNum: 1,
      pageSize: 15,
      tableData: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      tableData5: [],
      tableData6: [],
      payTypeStatus: [],
      otherReason: {},
      moneyShow: [],
      currentChargeVO: "",
      originalChargeVO: "",
      rowData: "",
      rowData2: "",
      rowData3: "",
      remarks: "",
      loading: false,
      form: {
        input1: "",
        input2: "",
        region1: "",
        value3: "",
        payType: "",
        parkId: "",
        plateNumber: "",
        startTime: "",
        endTime: "",
      },
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "orderNumber",
          label: "退款订单号",
          width: "",
        },
        {
          prop: "parentParkName",
          label: this.$t("list.park_name"),
          width: "120",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "120",
        },
        // {
        //   prop: 'refundType',
        //   label: '退款类型',
        //   width: '120'
        // },
        {
          prop: "orderShouldPay",
          label: this.$t("list.order_amount"),
          width: "160",
          formatter: (row, column) => {
            if (row.orderShouldPay) {
              return Number(row.orderShouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "actualRefundPay",
          label: this.$t("list.refund_amount"),
          width: "100",
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "refundModeName",
          label: "退回方式",
          width: "100",
        },
        {
          prop: "payType",
          label: "支付方式",
          width: "100",
          formatter: (row, column) => {
            for (let i = 0; i < this.payTypeStatus.length; i++) {
              if (this.payTypeStatus[i].code === row.payType) {
                return this.payTypeStatus[i].desc;
              }
            }
          },
        },
        {
          prop: "refundReasonName",
          label: this.$t("list.refund_reason"),
          width: "100",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Application_Time"),
          width: "100",
        },
      ],
      tableCols2: [
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "strExitTime",
          label: this.$t("list.leaving_time"),
          width: "",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
        {
          prop: "actualPay",
          label: "已收金额",
          width: "100",
          formatter: (row, column) => {
            if (row.actualPay) {
              return Number(row.actualPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
      ],
      tableCols3: [
        {
          prop: "payOrderId",
          label: "订单编号",
          width: "",
        },
        {
          prop: "payType",
          label: "支付方式",
          width: "120",
          formatter: (row, column) => {
            for (let i = 0; i < this.payTypeStatus.length; i++) {
              if (this.payTypeStatus[i].code === row.payType) {
                return this.payTypeStatus[i].desc;
              }
            }
          },
        },
        {
          prop: "shouldPay",
          label: "应付金额",
          width: "120",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "agioPay",
          label: this.$t("list.preferential_amount"),
          width: "120",
          formatter: (row, column) => {
            if (row.agioPay) {
              return Number(row.agioPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "money",
          label: this.$t("list.Actual_received_amount"),
          width: "120",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "payTime",
          label: this.$t("list.payment_time"),
          width: "",
        },
        {
          prop: "paymentId",
          label: "支付流水号",
          width: "",
        },
      ],
      tableCols4: [
        {
          prop: "refundModeName",
          label: this.$t("list.Refund_method"),
          width: "",
        },
        {
          prop: "payType",
          label: "支付方式",
          width: "",
          formatter: (row, column) => {
            for (let i = 0; i < this.payTypeStatus.length; i++) {
              if (this.payTypeStatus[i].code === row.payType) {
                return this.payTypeStatus[i].desc;
              }
            }
          },
        },
        {
          prop: "actualRefundPay",
          label: this.$t("list.refund_amount"),
          width: "",
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "refundReasonName",
          label: this.$t("list.refund_reason"),
          width: "",
        },
        {
          prop: "actualExitTime",
          label: this.$t("list.Actual_appearance_time"),
          formatter: (row) => {
            return row.actualExitTime
              ? dateFormat(new Date(row.actualExitTime * 1), "yyyy-MM-dd HH:mm:ss")
              : "";
          },
        },
        {
          prop: "remarks",
          label: "备注",
          width: "",
        },
        {
          prop: "operatorName",
          label: this.$t("list.applicant"),
          width: "100",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Application_Time"),
          width: "100",
        },
        {
          prop: "refundStateName",
          label: this.$t("list.state"),
          width: "",
        },
      ],
      tableCols5: [
        {
          prop: "index",
          label: "序号",
          width: "100",
        },
        {
          prop: "createdTime",
          label: "操作日志",
          width: "",
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "",
        },
        {
          prop: "sortNumName",
          label: "审批记录",
          width: "",
        },
      ],
      actualPayMoneyVal: "",
      refundrRecordLength: 0,
      refundTypeList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "手动申请退款",
          value: "1",
        },
        {
          label: "系统申请退款",
          value: "2",
        },
      ],
    };
  },
  watch: {
    tableData6: {
      handler(curVal, oldVal) {
        if (curVal.length > 0) {
          this.showParkRecord = this.tableData6[0].refundReasonName == "出场延时";
        }
      },
      deep: true,
    },
  },
  methods: {
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.form.startTime = timeArr[0];
      this.form.endTime = timeArr[1];
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.refundRecordlist();
    },
    showLog() {
      if (this.form.startTime && this.form.endTime) {
        let time = new Date(this.form.endTime) - new Date(this.form.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    affirmAgo(scope) {
      this.centerDialogVisible = true;
      this.rowData2 = scope.row.refundRecordId;
      this.remarks = scope.row.remarks;
    },
    // 确认退款 详情弹窗内centerDialogVisible = true
    affirm() {
      this.dialogVisible = false;
      this.centerDialogVisible = false;
      this.$axios
        .post("/acb/2.0/refundRecord/updateRefundAndFinish", {
          data: {
            refundRecordId: this.rowData2,
            // remarks: this.remarks  // 修复bug  注释
            remarks: "",
            // ,
            // tradeCode: ''
          },
        })
        .then((res) => {
          setTimeout((_) => {
            this.refundRecordlist();
          }, 300);
        });
    },
    particularsAgo(scope) {
      this.dialogVisible = true;
      this.moneyShow = [];
      this.$axios.get("/acb/2.0/refundRecord/getById/" + scope.row.refundRecordId).then((res) => {
        this.tableData6 = [];
        this.tableData6.push(res.value);
        this.dialogForm = res.value;
        this.dialogForm.plateNumber = "";
        this.dialogForm.payType = "";
        this.dialogForm.parkId = "";
        this.currentChargeVO = res.value.currentChargeVO;
        this.originalChargeVO = res.value.originalChargeVO;
        this.moneyShow.push(res.value.originalChargeVO);
        this.moneyShow.push(res.value.currentChargeVO);
        this.moneyShow.push(res.value.originalChargeVO);
        if (this.dialogForm.refundReason == 2) {
          this.otherReason.money = Number(res.value.actualRefundPay / 100).toFixed(2); // 实付金额
          this.otherReason.discountMoney = Number(res.value.parkDiscountRefundPay / 100).toFixed(2); // 车场折扣金额
          this.otherReason.parkCardMoney = Number(res.value.parkCardRefundPay / 100).toFixed(2); // 停车卡抵扣金额
          this.otherReason.couponMoney = Number(res.value.agioRefundPay / 100).toFixed(2); // 优惠券金额
          this.moneyShow.splice(2, 1, this.otherReason);
        }
      });
      this.rowData = scope.row.payOrderId;
      this.rowData2 = scope.row.refundRecordId;
      // 停车记录
      this.rowData3 = scope.row.parkRecordId;
      this.remarks = scope.row.remarks;
      this.getData();
      this.log();
      this.refundRecordIdFn();
    },
    // 停车记录
    refundRecordIdFn() {
      this.$axios.get("/acb/2.0/parkRecord/" + this.rowData3).then((res) => {
        this.tableData2 = [];
        this.tableData2.push(res.value);
        this.actualPayMoneyVal = this.tableData2[0].actualPay;
        console.log(this.actualPayMoneyVal);
      });
    },
    getData() {
      this.$axios
        .get("/acb/2.0/refundRecord/getpayOrderById?payOrderId=" + this.rowData)
        .then((res) => {
          this.tableData3 = [];
          this.tableData3.push(res.value);
        });
    },
    log() {
      this.$axios.get("/acb/2.0/refundRecord/queryById/" + this.rowData2).then((res) => {
        this.tableData5 = setIndex(this.pageNum, res.value);
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    refundRecordlist() {
      //      if (this.form.plateNumber == '') {
      //        this.form.carId = '';
      //      }
      let flag = this.showLog();
      if (!flag) {
        return;
      }
      this.$axios
        .get("/acb/2.0/refundRecord/list", {
          data: {
            page: this.pageNum,
            size: this.pageSize,
            refundState: "3,4",
            exclude: 1,
            // 退款订单编号
            orderNumber: this.form.input1,
            // 停车场名称
            parentParkId: this.form.parkId,
            // 车牌号
            plateNumber: this.form.plateNumber,
            // 支付方式
            payType: this.form.payType,
            // 时间段
            startTime: this.form.startTime ? this.form.startTime : this.date1,
            endTime: this.form.endTime ? this.form.endTime : this.date2,
            // refundType: this.form.refundType
          },
        })
        .then((res) => {
          this.tableData = setIndex(this.pageNum, res.value.list);
          this.total = res.value.total * 1 || 0;
        });
    },
    // 停车场名称
    completeValue(item) {
      if (item) {
        this.form.parkId = item.parkId;
        this.form.parkName = item.parkName;
      } else {
        this.form.parkId = "";
        this.form.parkName = "";
      }
    },
    // 车牌号
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    //  车牌号下拉点击事件
    handleSelect(item) {
      this.loading = false;
      this.form.plateNumber = item.plateNumber;
      this.form.carId = item.carId;
      //	      this.searParkRecordList();
    },
    payType() {
      this.$axios.get("/acb/2.0/payment/payTypeDictList").then((res) => {
        this.payTypeStatus = res.value;
      });
    },
  },
  created() {
    this.payType();
    this.defalutDate = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];
  },
  components: {
    myComponent,
    timeRangePick,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.lineHeight60
  line-height 60px
.el-form
  margin 25px 0
  padding-top 23px
.optionAreaOne
  display flex
.optionAreaThree
  display flex
.title
  margin-top 30px
</style>
